import { render, staticRenderFns } from "./balanceList.vue?vue&type=template&id=31b3d158&"
import script from "./balanceList.vue?vue&type=script&lang=js&"
export * from "./balanceList.vue?vue&type=script&lang=js&"
import style0 from "./balanceList.vue?vue&type=style&index=0&id=31b3d158&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports